import React from 'react';
import { Authenticator, ConfirmSignIn, RequireNewPassword, } from 'aws-amplify-react';
import { BarLoader } from 'react-spinners';
import {Auth} from '@aws-amplify/auth';
import {
    CognitoUserSession,
    CognitoUser,
    CognitoUserPool,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoAccessToken
} from 'amazon-cognito-identity-js';
import {parseUrl} from 'query-string';

import LoginTheme from '../components/login/LoginTheme';
import SignIn from '../components/login/SignIn';
import VerifyContact from '../components/login/VerifyContact';
import ForgotPassword from '../components/login/ForgotPassword';
import { loginWithSessionTokenAsync, GetAWSTokensWithCode } from '../helpers/api';
import { css } from "@emotion/core";
import '@aws-amplify/ui/dist/style.css';
import './loginScreen.css';




interface LoginScreenState {
    loading: boolean,
    backgroundImagePath?: string
    username: string
}

interface LoginScreenProps {
    region: string
    userPoolId: string
    userPoolWebClientId: string
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


export default class LoginScreen extends React.Component<LoginScreenProps, LoginScreenState> {
    constructor(props: LoginScreenProps) {
        super(props);
        this.state = {
            loading: false,
            username: '',
        }

        Auth.configure({
            ...this.props,
            authenticationFlowType: 'USER_PASSWORD_AUTH'
        });
    }
    async componentDidMount() {
        try {
            await this.initFreshdeskWidget();
            const { url, query: { code = '', ErrorCode = '' } = {} } = parseUrl(window.location.href);
            if (ErrorCode) {
                await Auth.signOut();
                window.location.replace(url);
            } else if (code) {
                await this.SSOSignIn(code.replace('#', ''), url);
            }
        } catch (err) {
            console.log(err);
        }
    }

    setBackgroundImage = async (backgroundImagePath: string) => {
        this.setState({ backgroundImagePath });
        localStorage.setItem('backgroundImagePath', backgroundImagePath);
    }

    SSOSignIn = async (code: string, redirectUrl: string): Promise<void> => {
        const { id_token, refresh_token, access_token } = await GetAWSTokensWithCode(code, redirectUrl);
        const IdToken = new CognitoIdToken({ IdToken: id_token });
        const RefreshToken = new CognitoRefreshToken({ RefreshToken: refresh_token });
        const AccessToken = new CognitoAccessToken({ AccessToken: access_token });
        const userPool = new CognitoUserPool({
            UserPoolId: this.props.userPoolId,
            ClientId: this.props.userPoolWebClientId,
        })
        const user = new CognitoUser({
            Username: IdToken.decodePayload().email,
            Pool: userPool,
        });
        const session = new CognitoUserSession({
            IdToken,
            RefreshToken,
            AccessToken
        });
        user.setSignInUserSession(session);
        // Reconfigure Auth to pickup our changes to the local storage
        Auth.configure(this.props);
        await this.tryLogin(session);
    }

    componentWillMount() {
        // @ts-ignore
        window.fwSettings = {
            "widget_id": 61000001202
        }
    }

    handleAuthStateChange = (state: string, data: any) => {
        if (state === 'signedIn') {
            Auth.currentSession()
                .then(this.tryLogin)
                .catch(err => console.log(err));
        }
    }

    onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({ username: event.target.value })

    initFreshdeskWidget = async () => {

        const freshdeskSrc = 'https://widget.freshworks.com/widgets/61000001202.js';

        const script = document.createElement('script');
        script.src = freshdeskSrc;
        script.async = true;

        document.body.appendChild(script);
    }

    tryLogin = async (session: CognitoUserSession) => {
        if (session) {
            try {
                this.setState({ loading: true });
                const idToken = session.getIdToken();
                const backgroundImagePath = localStorage.getItem('backgroundImagePath');
                if (backgroundImagePath) {
                    await this.setBackgroundImage(backgroundImagePath);
                }
                await loginWithSessionTokenAsync(idToken.getJwtToken());
            } catch (err) {
                console.log(err);
                await Auth.signOut();
            }
        }
    }

    render() {
        
        const { loading, backgroundImagePath, username } = this.state;
        
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                background: 'linear-gradient(to right top, #000046, #0ca9d9)',
                backgroundAttachment: 'fixed !important',
            }}>
                <span style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundImage: backgroundImagePath && `url(${backgroundImagePath})`,
                    backgroundAttachment: 'fixed !important',
                    backgroundPositionY: 'bottom',
                    backgroundSize: 'cover',
                    transition: 'opacity 0.5s linear',
                    opacity: backgroundImagePath ? 1 : 0
                }} />

                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                }}>
                    <img src="/Images/Login/Consent2GoLogo.png" style={{ width: '300px', marginBottom: '20px' }} />
                    <div style={{
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        width: '350px',
                        overflow: 'hidden'
                    }}>


                        <div style={{ height: 5 }}>
                            <BarLoader
                                height={5}
                                width={350}
                                color={'#fc4a1a'}
                                loading={loading}
                                css={override}
                            />
                        </div>

                        <Authenticator
                            theme={LoginTheme}
                            hideToast={true}
                            hideDefault={true}
                            onStateChange={this.handleAuthStateChange}
                            //Default cause its missing and seems to prevent the page flickering?
                            authState='signIn'
                        >
                            <SignIn
                                setLoading={loading => this.setState({ loading })}
                                setBackgroundImage={this.setBackgroundImage}
                                username={username}
                                onChangeUsername={this.onChangeUsername}
                            />
                            <ConfirmSignIn />
                            <RequireNewPassword />
                            <VerifyContact setLoading={loading => this.setState({ loading })} />
                            <ForgotPassword
                                setLoading={loading => this.setState({ loading })}
                                username={username}
                                onChangeUsername={this.onChangeUsername}
                            />
                        </Authenticator>
                        <div
                            id="copyright"
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                color: '#fff',
                                textAlign: 'center',
                                fontSize: '14px',
                                margin: '10px',
                                zIndex: 1
                            }}>
                            {`${new Date().getFullYear()} © MCB International ACN 149 360 743 - `}
                            <a href="/Content/Public/MCBSchoolsWebPrivacyPolicy.pdf" target="_blank" style={{ color: '#fff' }}>
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}