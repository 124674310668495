import React from 'react';
import Auth from '@aws-amplify/auth';
import {
    SignIn,
    FormSection,
    SectionBody,
    SectionFooter,
    InputRow,
    ButtonRow,
    Link
} from 'aws-amplify-react';
import { JS, ConsoleLogger as Logger } from '@aws-amplify/core';
import {parseUrl} from 'query-string';

import { loginWithSessionTokenAsync, GetAuthDetails } from '../../helpers/api';
import validator from "validator";
import isEmail = validator.isEmail;

interface CustomSignInProps {
    authState?: string;
    authData?: any;
    onStateChange?: (state: string) => void;
    theme?: any,
    setLoading: (loading: boolean) => void;
    setBackgroundImage: (backgroundImagePath: string) => void
    username: string
    onChangeUsername: (event: React.ChangeEvent<HTMLInputElement>) => void
};

const logger = new Logger('SignIn');

export default class extends SignIn<CustomSignInProps, any> {
    constructor(props: CustomSignInProps) {
        super(props);
        
        this.signIn = this.signIn.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.decideAuthenticationMethod = this.decideAuthenticationMethod.bind(this);

        this._validAuthStates = ['signIn', 'signedOut'];
        this.state = {
            isAuthRevealed: false,
            errorMessage: ""
        };
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    async onKeyDown(e: any) {
        if (this.props.authState === 'signIn') {
            if (e.keyCode === 13) { // when press enter 
                if (this.state.isAuthRevealed) {
                    await this.signIn();
                } else {
                    await this.decideAuthenticationMethod();
                }
            }
        }
    }
    
    error = (message: string) => {
        this.setState({
            errorMessage: message 
        });
    } 

    async signIn() {
        this.error('');
        const { username } = this.props;
        const { password } = this.inputs;
        if (!Auth || typeof Auth.signIn !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        const { setLoading } = this.props;
        setLoading(true);
        if (username) {
            try {
                const user: any = await Auth.signIn(username.toLowerCase(), password);
                logger.debug(user);
                if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    setLoading(false);
                    logger.debug('confirm user with ' + user.challengeName);
                    this.changeState('confirmSignIn', user);
                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setLoading(false);
                    logger.debug('require new password', user.challengeParam);
                    this.changeState('requireNewPassword', user);
                } else if (user.challengeName === 'MFA_SETUP') {
                    setLoading(false);
                    logger.debug('TOTP setup', user.challengeParam);
                    this.changeState('TOTPSetup', user);
                } else {
                    
                    Auth.verifiedContact(user)
                        .then(async data => {
                            if (!JS.isEmpty(data.verified)) {
                                const session = user.getSignInUserSession();
                                if (session) {
                                    await loginWithSessionTokenAsync(session.getIdToken().getJwtToken());
                                }
                            } else {
                                setLoading(false);
                                const userData = Object.assign(user, data);
                                this.changeState('verifyContact', userData);
                            }
                        });
                }
            } catch (err) {
                setLoading(false);
                if (err.code === 'UserNotConfirmedException') {
                    logger.debug('the user is not confirmed');
                    this.changeState('confirmSignUp');
                } else {
                    this.error('Sign in failed. Please check your login details.');
                }
            }
        }
    }

    async decideAuthenticationMethod() {
        this.error('');
        const { setLoading, setBackgroundImage } = this.props;
        const { username } = this.props;
        if (username) {
            try {
                setLoading(true);
                const { url } = parseUrl(window.location.href);
                const { SSOAuthorizeUrl, BackgroundImagePath } = await GetAuthDetails(username, url);
                if (BackgroundImagePath) {
                    setBackgroundImage(BackgroundImagePath);
                }
                if (SSOAuthorizeUrl) {
                    return window.location.replace(SSOAuthorizeUrl);
                } else {
                    // Reveal regular authentication 
                    setLoading(false);
                    this.setState({ isAuthRevealed: true })
                }
            }
            catch (err) {
                setLoading(false);
                this.setState({ isAuthRevealed: true })
            }
        }
    }

    showComponent(theme: any) {
        const { username, onChangeUsername } = this.props;
        const buttonDisabled = !(username && isEmail(username));

        return (
            <FormSection theme={theme}>
                <SectionBody theme={theme}>
                    <InputRow
                        autoFocus
                        placeholder={'Email'}
                        theme={theme}
                        key="username"
                        name="username"
                        value={username}
                        onChange={onChangeUsername}
                    />
                    <div style={{
                        overflow: 'hidden',
                        maxHeight: this.state.isAuthRevealed ? '200px' : 0,
                        transition: 'max-height 0.5s ease-in-out'
                    }}>
                        <InputRow
                            placeholder={'Password'}
                            theme={theme}
                            key="password"
                            type="password"
                            name="password"
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <ButtonRow
                        theme={theme}
                        onClick={this.state.isAuthRevealed ? this.signIn : this.decideAuthenticationMethod}
                        disabled={buttonDisabled}
                        id={buttonDisabled ? 'disabledButton' : undefined}
                    >
                        {this.state.isAuthRevealed ? 'Sign In' : 'Next'}
                    </ButtonRow>
                </SectionBody>
                <SectionFooter theme={theme}>
                    <Link theme={theme} style={{ display: this.state.isAuthRevealed ? 'block' : 'none' }} onClick={() => this.changeState('forgotPassword', this.inputs.username)}>
                        Reset Password
                    </Link>
                </SectionFooter>
                <>
                {this.state.errorMessage && (<div style={{padding: "10px", textAlign: "center", backgroundColor: "orangered", color: "white"}} className="error-message">{this.state.errorMessage}</div>)}
                </>
            </FormSection>
        )
    }
}